import React, { useEffect, useState } from "react"
import Foresta from "../../images/wallpapers/fireworks.png"
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import * as client from '../../Integration/api';
import { ClipLoader } from 'react-spinners';
import { Configuration } from '../../Integration';
import { useTranslation } from "react-i18next";

interface props {
    setWarning: React.Dispatch<React.SetStateAction<string>>,
}

export const Final: React.FC<props> = ({ setWarning }) => {

    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const token = sessionStorage.getItem("sessionToken")

    useEffect(() => {
        if (loading) {
            const conf: Configuration = {
                accessToken: token ? token : "",
                baseOptions: {
                    headers: {
                        "Access-Control-Allow-Origin": "*"
                    }
                },
            };
            const clientAPI = new client.DefaultApi(conf)
            setLoading(true)
            clientAPI.generateReport().then(res => {
                setLoading(false)
            }).catch(error => {
                console.error(error)
                if (error.response && error.response.status === 403) {
                    setWarning("session Expired")
                    setTimeout(() => {
                        navigate("/openES");
                    }, 150000);
                }
                else if (error.response && error.response.status === 450) {
                    setWarning(t("warning12"))
                    setTimeout(() => {
                        navigate("/openES");
                    }, 150000);
                }
                else {
                    setWarning(t("warning13"))
                    setTimeout(() => {
                        navigate("/scope1-S1");
                    }, 150000);
                }
            })

        }
    }, [loading]);


    return (
        <Grid
            container
            direction="column"
            display='flex'
            justifyContent="center"
            alignItems="center"
            gap="2vh"
            style={{ background: "white", minHeight: "93vh" }}
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                gap="2vw"
                marginTop={'12vh'}
                marginBottom={'1vh'}
            >
                {loading ? (<ClipLoader size={200} color={'#007BFF'} loading={loading} />)
                    :
                    (
                        <>

                            <img alt="sfondo" width="19%" src={Foresta}  ></img>

                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <h2 style={{ color: "#017CC0", textAlign: "center", fontSize: "1.2vw" }} >ECOGENIUS - CARBON ESTIMATOR</h2>
                                <h3 style={{ color: "#0160A1", textAlign: "center", fontSize: "1vw", marginTop: "-1vh" }} >{t("label1Final")}</h3>
                                <Button
                                    variant="outlined"
                                    style={{ color: "white", borderColor: "white", background: "#0160A1", fontSize: "1vw" }}
                                    onClick={e => navigate("/report")}
                                    size="medium"
                                >{t("label2Final")}</Button>
                            </div>
                        </>
                    )
                }
            </Grid>

        </Grid>

    )

}