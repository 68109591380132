import React from 'react'
import '../../App.css'

interface props {

    accuracy: string
    text1: string
    text2: string
}

export const Accuracy: React.FC<props> = ({ accuracy, text1, text2 }) => {

    const getBarClasses = (index: number) => {
        switch (index) {
            case 0:
                return 'bar-low-full';
            case 1:
                return accuracy === 'GOOD' ? 'bar-medium' : 'bar-medium-full';
            case 2:
                return (accuracy === 'HIGH' || accuracy === 'GOOD')  ? 'bar-high' : 'bar-high-full';
            default:
                return '';
        }
    };

    return (
        <div className="accuracy-box">
            <div style={{paddingRight:"10px", marginBottom:"0", width:"8vw"}}>{text1}: {text2}</div>
            <div className="bars-container">
                {[0, 1, 2].map((index) => (
                    <div key={index} className={` ${getBarClasses(index)}`}></div>
                ))}
            </div>
        </div>
    )
}
